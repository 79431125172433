<template>
    <vue-scroll class="page-devis-auto" >
        <div class="page-header">
			<h1>Simulation de devis <span style="color:#ffa409">Auto/Moto</span></h1>
		</div>

        <div v-if="e1 == 2" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <el-row class="row">
                <el-col :span="12">
                    <el-row :gutter="12">
                        <el-col :span="12">
                            <div>
                                <h3>Catégorie du véhicule :</h3>
                                <el-row :gutter="15" justify="center">
                                    <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio v-model="categorie" label="tourisme">Véhicule Particulier (VP)</el-radio>
                                    </div>
                                    </el-col>
                                    <el-col><div class="grid-content bg-purple-light">
                                        <el-radio v-model="categorie" label="2_roues">2 Roues (jusqu'à 4 roues max 150kgs)</el-radio>
                                    </div>
                                    </el-col>
                                    <el-col><div class="grid-content bg-purple-light">
                                        <el-radio v-model="categorie" label="utilitaire">Véhicule Utilitaire (transport de matériel)</el-radio>
                                    </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div v-if="categorie === '2_roues'" style="margin-top:15px">
                                <div>
                                    <h3>Volumétrie 2 roues :</h3>
                                    <el-row :gutter="15" justify="center">
                                        <el-col>
                                            <div class="grid-content bg-purple">
                                                <el-radio v-model="volumetrie2_roues" label="50" v-show="optionUtilitaire!='materiel_tiers'">jusqu'à 50 cm3</el-radio>
                                            </div>
                                        </el-col>
                                        <el-col>
                                            <div class="grid-content bg-purple-light">
                                                <el-radio v-model="volumetrie2_roues" label="125">jusqu'à 125 cm3</el-radio>
                                            </div>
                                        </el-col>
                                        <el-col>
                                            <div class="grid-content bg-purple">
                                                <el-radio v-model="volumetrie2_roues" label="126">plus de 125 cm3</el-radio>
                                            </div>
                                        </el-col>
                                        <el-col>
                                            <div class="grid-content bg-purple">
                                                <el-radio v-model="volumetrie2_roues" label="401">Side-car</el-radio>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>

                            <div v-if="categorie !== '2_roues'" style="margin-top:15px">
                                <div>
                                    <h3>Carburation :</h3>
                                    <el-row :gutter="15" justify="center">
                                        <el-col>
                                            <div class="grid-content bg-purple">
                                                <el-radio v-model="carburant" label="Essence">Essence</el-radio>
                                            </div>
                                        </el-col>
                                        <el-col>
                                            <div class="grid-content bg-purple-light">
                                                <el-radio v-model="carburant" label="Diésel">Diesel</el-radio>
                                            </div>
                                        </el-col>
                                        <el-col>
                                            <div class="grid-content bg-purple">
                                                <el-radio v-model="carburant" label="Electrique">Eléctrique</el-radio>
                                            </div>
                                        </el-col>
                                        <el-col>
                                            <div class="grid-content bg-purple">
                                                <el-radio v-model="carburant" label="Hybride">Hybride</el-radio>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                    <div>
                        <div v-if="categorie === 'utilitaire'" style="margin-top:15px">
                            <div>
                                <h3>Option véhicule utilitaire :</h3>
                                <el-row :gutter="15" justify="center">
                                    <el-col>
                                        <div class="grid-content bg-purple">
                                            <el-radio v-model="optionUtilitaire" label="materiel_perso">Matériel personnel</el-radio>
                                        </div>
                                    </el-col>
                                    <el-col>
                                        <div class="grid-content bg-purple-light">
                                            <el-radio v-model="optionUtilitaire" label="materiel_tiers">Materiel tierce</el-radio>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>

                            <div>
                                <h3>Option Tonnage Véhicule :</h3>
                                <el-row :gutter="15" justify="center">
                                    <el-col>
                                        <div class="grid-content bg-purple">
                                            <el-radio v-model="tonnage_carosserie" label="carosserie tourisme" v-show="optionUtilitaire!='materiel_tiers'">Carosserie Tourisme</el-radio>
                                        </div>
                                    </el-col>
                                    <el-col>
                                        <div class="grid-content bg-purple-light">
                                            <el-radio v-model="tonnage_carosserie" label="Autre carosserie jusqu'à 3,5 tonnes">Autre carosserie jusqu'à 3,5 tonnes</el-radio>
                                        </div>
                                    </el-col>
                                    <el-col>
                                        <div class="grid-content bg-purple">
                                            <el-radio v-model="tonnage_carosserie" label="Autre carosserie plus de 3,5 tonnes">Autre carosserie plus de 3,5 tonnes</el-radio>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </el-col>

                <el-col :span="12"  v-if="categorie !== '2_roues'">
                    <h3>Détails du véhicule :</h3>
                    <el-row :gutter="15">
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <label>Nombre de chevaux</label>
                                <el-select style="width: 100%!important;" v-model="force" placeholder="Selectionner">
                                    <el-option
                                    v-for="items in PuissanceFinscaleItems"
                                    :key="items"
                                    :label="items"
                                    :value="items">
                                    </el-option>
                                </el-select>
                            </div>
                        </el-col>

                        <el-col :span="8">
                            <div class="grid-content bg-purple-light">
                                <label>Nombre de places</label>
                                <el-input type="number" placeholder="Nombre de places" v-model="nbplace">
                                <template slot="append">place(s)</template>
                                </el-input>
                            </div>
                        </el-col>

                        <el-col :span="8">
                            <div class="grid-content bg-purple" v-if="categorie !== '2_roues'">
                                <label>Valeur actuelle</label>
                                <el-input v-money="money" placeholder="Valeur actuelle" v-model="valeur_venale">
                                    <template slot="append">CFA</template>
                                </el-input>
                            </div>
                        </el-col> 
                    </el-row>

                    <el-row :gutter="15">
                        <el-col :span="12" style="margin-top: 20px;">
                                <div class="grid-content bg-purple">
                                    <label>Date 1ére mise en circulation</label>
                                    <el-date-picker
                                        style="width: 100%!important;"
                                        @change="yearsDiff()"
                                        v-model="dateCircule"
                                        type="date"
                                        placeholder="Choississez une date"
                                        :picker-options="dateBeforeToday">
                                    </el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="12" style="margin-top: 20px;">
                            <div class="grid-content bg-purple-light">
                                <label>Âge du véhicule</label>
                                <el-input readonly placeholder="Âge du véhicule" v-model="age">
                                        <template slot="append">{{annee_mois}}</template>
                                    </el-input>
                            </div>
                            </el-col>
                    </el-row>
        
                 
                </el-col>
            </el-row>

            <el-divider></el-divider>

            <el-row>
                <div style="margin-top:15px">
                <!-- <el-button style="float:left" @click="e1 =0" round>Retour</el-button> -->
                <el-button @click="Stepper1Validator" type="primary" round>Suivant</el-button>
            </div>
            </el-row>
        </div>

        <div v-if="e1 == 3" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">

            <el-row :gutter="15">
                <el-col :span="6">
                    <el-row>
                        <div>
                            <h3>Garanties :</h3>
                            <div class="grid-content bg-purple" v-show="showOffre">
                                <el-row :gutter="15" justify="center">
                                    <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio v-model="offre" label="offre_pack" @change="show_offre()">packs</el-radio>
                                    </div>
                                    </el-col>
                                    <el-col><div class="grid-content bg-purple-light">
                                        <el-radio v-model="offre" label="offre_carte" @change="show_offre()">À la carte</el-radio>
                                    </div>
                                    </el-col>
                                </el-row>
                            </div>

                            <el-divider></el-divider>

                            <div class="grid-content bg-purple" v-show="offre_pack" v-if="categorie != '2_roues'">
                                <el-row :gutter="15" justify="center">
                                    <el-col>
                                        <div class="grid-content bg-purple">
                                            <el-tooltip placement="right">
                                                <div slot="content">Garanties : <br/>RESPONSABILITE CIVILE <br/>PERSONNES TRANSPORTÉES<br/>DÉFENSE ET RECOURS</div>
                                            <el-radio v-model="garanties" :label="packStandart.garanties" @change="showGaranties()">Pack Standard</el-radio>
                                            </el-tooltip>
                                        </div>
                                    </el-col>
                                    <el-col>
                                        <div class="grid-content bg-purple-light">
                                            <el-tooltip placement="right">
                                                <div slot="content">Garanties : <br/>RESPONSABILITE CIVILE <br/>PERSONNES TRANSPORTÉES<br/>DÉFENSE ET RECOURS<br/>BRIS DE GLACES</div>
                                            <el-radio v-model="garanties" :label="packIntermediaire.garanties" @change="showGaranties()">Pack Intermédiaire</el-radio>
                                            </el-tooltip>
                                        </div>
                                    </el-col>
                                    <el-col>
                                        <div class="grid-content bg-purple">
                                            <el-tooltip placement="right">
                                                <div slot="content">Garanties : <br/>RESPONSABILITE CIVILE <br/>PERSONNES TRANSPORTÉES<br/>DÉFENSE ET RECOURS<br/>BRIS DE GLACES<br/>VOL<br/>INCENDIE<br/>AVANCE SUR RECOURS</div>
                                            <el-radio v-model="garanties" :label="packAvance.garanties" @change="showGaranties()">Pack Avancé</el-radio>
                                            </el-tooltip>
                                        </div>
                                    </el-col>
                                    <el-col>
                                        <div class="grid-content bg-purple-light">
                                            <el-radio v-model="garanties" :label="packFull" @change="showGaranties()">Pack Full</el-radio>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>

                            <div class="grid-content bg-purple" v-show="offre_full" v-if="categorie != '2_roues'">
                                <el-row :gutter="15" justify="center">
                                    <el-col>
                                        <div class="grid-content bg-purple">
                                            <el-tooltip placement="right">
                                                <div slot="content">Garanties : <br/>RESPONSABILITE CIVILE <br/>PERSONNES TRANSPORTÉES<br/>DÉFENSE ET RECOURS<br/>BRIS DE GLACES<br/>VOL<br/>INCENDIE<br/>AVANCE SUR RECOURS<br/>TIERCE COMPLÈTE</div>
                                            <el-radio v-model="garanties" :label="packFull.packToutRisques.garanties" @change="showGarantiesPackFull()">Pack Full - Tous risques</el-radio>
                                            </el-tooltip>
                                        </div>
                                    </el-col>
                                    <el-col>
                                        <div class="grid-content bg-purple-light">
                                            <el-tooltip placement="right">
                                                <div slot="content">Garanties : <br/>RESPONSABILITE CIVILE <br/>PERSONNES TRANSPORTÉES<br/>DÉFENSE ET RECOURS<br/>BRIS DE GLACES<br/>VOL<br/>INCENDIE<br/>AVANCE SUR RECOURS<br/>TIERCE COLLISION</div>
                                            <el-radio v-model="garanties" :label="packFull.packFullCollision.garanties" @change="showGarantiesPackFull()">Pack Full - Collision</el-radio>
                                            </el-tooltip>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>

                            <div class="grid-content bg-purple" v-show="offre_carte" v-if="categorie != '2_roues'">            
                                <el-checkbox-group v-model="garanties">
                                    <el-checkbox v-show="categorie != '2_roues'" label="RESPONSABILITE CIVILE">Responsabilité Civile</el-checkbox>
                                    <el-checkbox v-show="categorie != '2_roues'" label="INCENDIE">Incendie</el-checkbox>
                                    <el-checkbox v-show="categorie != '2_roues'" label="AVANCE SUR RECOURS" @change="AvRecModalFunction()">Avance sur Recours</el-checkbox>
                                    <el-checkbox v-show="categorie != '2_roues'" label="DEFENSE ET RECOURS">Défense et Recours</el-checkbox>
                                    <el-checkbox v-show="categorie != '2_roues'" label="VOL">Vol</el-checkbox>
                                    <el-checkbox v-show="categorie != '2_roues'" label="BRIS DE GLACES">Bris de glace</el-checkbox>
                                    <el-checkbox v-show="categorie != '2_roues'" label="PERSONNES TRANSPORTEES" @change="PersoTransporteModalFunction()">Personnes transportées</el-checkbox>
                                    <el-checkbox v-show="categorie != '2_roues'" label="TIERCE COMPLETE" @change="tierceControl">Tierce Complète</el-checkbox>
                                    <el-checkbox v-show="categorie != '2_roues'" label="TIERCE COLLISION" @change="tierceControl">Tierce Collision</el-checkbox>
                                </el-checkbox-group>
                            </div>

                            <div class="grid-content bg-purple" v-show="offre_carte" v-if="categorie == '2_roues'">            
                                <el-checkbox-group v-model="garanties">
                                    <el-checkbox label="RESPONSABILITE CIVILE">Responsabilité Civile</el-checkbox>
                                    <el-checkbox label="DEFENSE ET RECOURS">Défense et Recours</el-checkbox>
                                    <el-checkbox label="PERSONNES TRANSPORTEES" @change="PersoTransporteModalFunction()">Personnes Transportées</el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </div>
                    </el-row>
                </el-col>
                <el-col :span="13">
                    <div v-if="flotte_mono == 'mono' || nbAutoAdded==nombreVehicules">
                    <h3>Liste des assureurs</h3>
                    <el-row :gutter="20" type="flex" justify="center">
                        <el-col :span="24">
                            <div class="grid-content bg-purple-dark">
                                <el-checkbox-group v-model="assureurs">
                                    <el-checkbox v-for="(assur,index) in listeAssureurs" :key="index" :label="assur.societe">
                                        <template>
                                            <img width="50" height="40" :src="assur.logo">
                                        </template>
                                    </el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                </el-col>
                <el-col :span="5">
                    <div>
                    <h3>Période de couverture</h3>
                    <el-row :gutter="15" v-if="!isMobile">
                        <el-col :span="24">
                            <div class="grid-content bg-purple">
                                <label>Durée de la garantie (en mois)</label>
                                <el-select @change="dateDiff()" style="width:100%" v-model="duree" placeholder="Selectionner">
                                    <el-option  v-for="item in DureeMois" :key="item" :label="item" :value="item"></el-option>
                                </el-select>
                            </div>
                        </el-col>
                        <el-col :span="24">
                            <div class="grid-content bg-purple mt-5">
                                <label>Date d'effet</label>
                                <el-date-picker
                                    style="width: 100%!important;"
                                    @change="dateDiff()"
                                    v-model="dateEffet"
                                    type="date"
                                    placeholder="Date d'effet"
                                    :picker-options="dateAfterToday">
                                </el-date-picker>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                </el-col>
            </el-row>

            <el-divider></el-divider>

            <el-row :gutter="15">
                <el-col :span="5">
                    <div style="margin-top:15px">


            <el-button style="float:left" @click="e1 =2" round>Retour</el-button>
                    <el-button  v-if="nbAutoAdded >= nombreVehicules" v-loading.fullscreen.lock="modalgenerationloading" @click="SendData()" type="primary" round>Simuler</el-button>
                </div>
                </el-col>
                <el-col :span="19">
                    <pre v-if="this.simulatorResult != null">
                        {{ this.simulatorResult }}
                    </pre>
                </el-col>
            </el-row>
        </div>

        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="!isMobile">
            <el-form ref="form"  label-width="120px" label-position="top">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="nom"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Numéro de téléphone</label>
                        <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                    </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Sexe</label>
                         <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                            <el-option label="Homme" value="homme"></el-option>
                            <el-option label="Femme" value="femme"></el-option>
                            <el-option label="Entreprise" value="entreprise"></el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>

        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="isMobile">
            <el-form ref="form" label-width="120px" label-position="top">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="nom"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                </div>
                </el-col>
                 <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <label>Numéro de téléphone</label>
                        <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                    </div>
                </el-col>
                 <el-col :span="24" style="margin-top:15px">
                    <div class="grid-content bg-purple">
                        <label>Sexe</label>
                         <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                            <el-option label="Homme" value="homme"></el-option>
                            <el-option label="Femme" value="femme"></el-option>
                            <el-option label="Entreprise" value="entreprise"></el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>

        <el-dialog title="Ajout véhicule" :visible.sync="popupAutre">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row :gutter="15">
                    <el-col :span="12">
                        <el-form-item label="Marque du véhicule">
                            <el-input placeholder="Marque de votre véhicule" v-model="marque"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="Modéle du véhicule">
                            <el-input placeholder="Modèle de votre véhicule" v-model="model"></el-input>
                        </el-form-item>
                    </el-col>

                 
                </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="validMarqueModel">Valider</el-button>
            </div>
        </el-dialog>

        <el-dialog show-close="false" title="Choix du Capital Avance sur Recours" :visible.sync="AvRec">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row justify="center">
                    <el-col>
                    <div class="grid-content bg-purple">
                        <el-radio v-model="capital_AvRec" label="500000">500 000 CFA</el-radio>
                    </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple-light">
                         <el-radio v-model="capital_AvRec" label="1000000">1 000 000 CFA</el-radio>
                        </div>
                    </el-col>
                     <el-col>
                        <div class="grid-content bg-purple-light">
                         <el-radio disabled v-model="capital_AvRec" label="0">Plus de 1 million CFA <span>Nous consulter</span></el-radio>
                        </div>
                    </el-col>
                </el-row>   
            </el-form>
            <div style="margin-top:15px">
                 <el-button @click="AvRecValidator" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>

        <el-dialog show-close="false" title="Personnes Transportées" :visible.sync="PersoTransporteModal">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row justify="center">
                    <el-col>
                    <div class="grid-content bg-purple">
                        <el-radio v-model="optionPersoTrans" label="option 1">Option 1</el-radio>
                    </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple-light">
                         <el-radio v-model="optionPersoTrans" label="option 2">Option 2</el-radio>
                        </div>
                    </el-col>
                     <el-col>
                        <div class="grid-content bg-purple-light">
                         <el-radio v-model="optionPersoTrans" label="option 3">Option 3</el-radio>
                        </div>
                    </el-col>
                </el-row>   
            </el-form>
            <div style="margin-top:15px">
                 <el-button @click="PersonnesTransValidator()" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>

        <el-dialog show-close="false" title="Individuel transport" :visible.sync="individiA">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row justify="center">
                    <el-col>
                    <div class="grid-content bg-purple">
                        <el-radio v-model="option_Individuel_Accident" label="option 1">Option 1</el-radio>
                    </div>
                    </el-col>
                    <el-col>
                        <div class="grid-content bg-purple-light">
                         <el-radio v-model="option_Individuel_Accident" label="option 2">Option 2</el-radio>
                        </div>
                    </el-col>
                     <el-col>
                        <div class="grid-content bg-purple-light">
                         <el-radio v-model="option_Individuel_Accident" label="option 3">Option 3</el-radio>
                        </div>
                    </el-col>
                </el-row>   
            </el-form>
            <div style="margin-top:15px">
                 <el-button @click="individuelTransValidator()" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>
        
        <!-- ========= DIALOG VALEUR A NEUF ============ -->
        <!-- ========= DIALOG VALEUR A NEUF ============ -->
        <el-dialog show-close="false" title="Valeur à neuf" :visible.sync="VNeuf">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row :gutter="15" justify="center">
                   <el-col :span="12">
                        <el-form-item label="Valeur actuelle">
                            <el-input v-money="money" placeholder="Valeur actuelle (CFA)" v-model="valeur_venale"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Valeur à neuf">
                            <el-input v-money="money" placeholder="Valeur à neuf (CFA)" v-model="valeur"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>   
            </el-form>
            <div>
                 <el-button @click="CheckValeurNeuf()" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>

        <!-- ========= DIALOG DATE 1ERE MISE EN CIRCULATION ============ -->
        <!-- ========= DIALOG DATE 1ERE MISE EN CIRCULATION ============ -->
        <el-dialog show-close="false" title="Date 1ére mise en circulation" :visible.sync="dateMiseEnCirculeModal">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row :gutter="15">
                <el-col :span="12" style="margin-top: 20px;">
                    <div class="grid-content bg-purple">
                        <label>Date 1ére mise en circulation</label>
                        <el-date-picker
                            style="width: 100%!important;"
                            @change="yearsDiff()"
                            v-model="dateCircule"
                            type="date"
                            placeholder="Choississez une date"
                            :picker-options="dateBeforeToday">
                        </el-date-picker>
                    </div>
                </el-col>
                <el-col :span="12" style="margin-top: 20px;">
                    <div class="grid-content bg-purple-light">
                        <label>Âge du véhicule</label>
                        <el-input readonly placeholder="Âge du véhicule" v-model="age">
                                <template slot="append">{{annee_mois}}</template>
                            </el-input>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top: 20px;">
                 <el-button @click="CheckDateMiseEnCirculation()" type="primary" round>Valider</el-button>
            </div>
        </el-dialog>

        <el-dialog width="70%" title="Mes véhicules" :visible.sync="ModalListeContrat" v-if="ModalListeContrat && !isMobile">
           <el-table :data="userCars" class="padded-table" height="300" style="width: 100%" row-key="id">
                  <el-table-column prop="marque" label="Marque" min-width="200"> </el-table-column>
                  <el-table-column prop="model" label="Modèle" min-width="150"> </el-table-column>
                  <el-table-column prop="nbplace" label="Places" min-width="150">
                  </el-table-column>
                  <el-table-column prop="force" label="Force" min-width="100"> </el-table-column>
                    <el-table-column fixed="right" label="Actions" prop="action" min-width="100">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="getCarForDevis(scope.row)" type="primary" icon="el-icon-right" circle title="Voir détails"></el-button>
                            <el-button size="mini" @click="deleteUserCar(scope.row)" type="danger" icon="el-icon-delete" circle title="Désarchiver"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
        </el-dialog>

        <el-dialog width="90%" title="Mes véhicules" :visible.sync="ModalListeContrat" v-if="ModalListeContrat && isMobile">
           <el-table :data="userCars" class="padded-table" style="width: 100%" row-key="id">
                  <el-table-column prop="marque" label="Marque" min-width="150"> </el-table-column>
                  <el-table-column prop="model" label="Modèle" min-width="80"> </el-table-column>
                    <el-table-column label="Actions" prop="action" min-width="100">
                    <template slot-scope="scope">
                        <el-button @click="getCarForDevis(scope.row)" type="primary" icon="el-icon-right" size="mini" circle title="Voir détails"></el-button>
                        <el-button @click="deleteUserCar(scope.row)" type="danger" icon="el-icon-delete" size="mini" circle title="Désarchiver"></el-button>
                    </template>
                    </el-table-column>
                </el-table>
        </el-dialog>

    </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import MarqueVehicule from '../../../common/marqueauto'
import Marque2roues from '../../../common/2roues'
import configAuto from '../../../common/configAuto'
import _ from 'underscore'
import {
  mask
} from 'vue-the-mask'
//fin importation assuraf
import customPassword from "../../../services/password_generator"
const validatePhone = phone => {

  if(!phone.length){
    return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
  }else{
    return { valid: true, error: null };
  }

};

const validateEmail = email => {
  if (!email.length) {
    return {valid: false, error: "L'adresse e-mail est obligatoire!"};
  } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    return { valid: false, error: "Veuillez entrer un email valide!" };
  } else {
    return { valid: true, error: null };
  }
};


export default {
    name: "sim_devis_auto",
    directives: {
        mask,
    },
    data() {
        return {
            simulatorResult : null,
            userRole: '',
            modalgenerationloading : false,
            userCarId : null,
            carDetails : {},
            ModalListeContrat : false,
            userCars : [],
            myCars : null,
            listCars : [],
            DureeMois: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            PersoTransporteModal : false,
            isMobile: false,
          PuissanceFinscaleItems: ['2', '3', '4', '5', '6', '7', '8', '9', '10', 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, '24+'],
          errors : {},
           headerContrat: [
          {
        text: "Marque",
        sortable: false,
        value: "marque"
        },
        {
          text: "Modele",
          sortable: false,
          value: "model"
        },
        {
          text: "Immatriculation",
          sortable: false,
          value: "Immatriculation"
        },
        {
          text: "Nombre de places",
          sortable: false,
          value: "nbplace"
        },
        {
          text: "Force",
          sortable: false,
          value: "force"
        },
        {
          text: "Date 1ère Circulation",
          sortable: false,
          value: "dateCircule"
        },
        {
          text: "Valeur actuelle",
          sortable: false,
          value: "valeur_venale"
        },
        {
          text: "Action",
          value: "action"
        }
      ],
      headerContratMobile: [
        {
          text: "Marque",
          sortable: false,
          value: "marque"
        },
        {
          text: "Modele",
          sortable: false,
          value: "model"
        },
        {
          text: "Immatriculation",
          sortable: false,
          value: "Immatriculation"
        }
      ],
            dateBeforeToday: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            dateAfterToday: {
                disabledDate(time) {
                    return time.getTime() <  (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
                }
            },
            AvRec : false,
            individiA : false,
            personnesTrans : false,
            NumberInput : false,
            EmailInput : false,
            mask: 'XX-XXXX-XX',
            radio: '1',
            //initialisation des models d'assuraf

            switch1: true,
            optionsFlotte: [{
                value: 2,
                label: '2'
            }],
        showOffre : true,
          offre_full : false,
          PackFull_Choix : null,
          text_packStandart : false,


          text_packInter : false,
          text_packAvance : false,
          text_packFull_1 : false,
          text_packFull_2 : false,
          text_packFull_3 : false,
          offre: 'offre_pack',
          offre_pack : true,
          offre_pack_full : false,
          offre_carte : false,
          packS : '',
          packI : '',
          packA : '',
          packStandart : {
            garanties : ['RESPONSABILITE CIVILE','PERSONNES TRANSPORTEES','DEFENSE ET RECOURS']
          },
          packIntermediaire : {
            garanties : ['RESPONSABILITE CIVILE','PERSONNES TRANSPORTEES','DEFENSE ET RECOURS','BRIS DE GLACES']
          },
          packAvance : {
            garanties : ['RESPONSABILITE CIVILE','PERSONNES TRANSPORTEES','DEFENSE ET RECOURS','BRIS DE GLACES','VOL','INCENDIE','AVANCE SUR RECOURS']
          },
          packFull : {
            packToutRisques : {
              garanties : ['RESPONSABILITE CIVILE','PERSONNES TRANSPORTEES','DEFENSE ET RECOURS','BRIS DE GLACES','VOL','INCENDIE','AVANCE SUR RECOURS','TIERCE COMPLETE']
            },
            packFullCollision : {
              garanties : ['RESPONSABILITE CIVILE','PERSONNES TRANSPORTEES','DEFENSE ET RECOURS','BRIS DE GLACES','VOL','INCENDIE','AVANCE SUR RECOURS','TIERCE COLLISION']
            }
          },
            VNeuf :  false,
            dateMiseEnCirculeModal : false,
            neant: 'N.A',
            optionsdate: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                minDate: new Date(),
            },
            optionsdateMEC: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                maxDate: new Date(),
            },
            optionsdateUser: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
                defaultDate: (dayjs(new Date()).add(-19, 'year')).toDate(),
                maxDate: (dayjs(new Date()).add(-18, 'year')).toDate()
            },
            e1: 2,
            dateCircule: null,
            money: {
                thousands: ' ',
                precision: false,
                masked: false
            },
            telephoneclient: null,
            emailclient: null,
            emailC: this.emailclient,
            telephoneC: this.telephoneclient,
            clientnonOk: false,
            clientOk: false,
            clientsearch: null,
            showId: false,
            showconfirmclient: false,
            recupIdClient: '',
            shownameclient: true,
            hidesearchClient: true,
            nom: null,
            prenom: null,
            email: null,
            datenaissance: null,
            profession: null,
            AddUser: false,
            adresse: null,
            piece_identite: null,
            numero_piece: null,
            seen: true,
            hideDive_vehicule: null,
            flotte_mono: null,
            nombreVehicules: null,
            categorie: null,
            volumetrie2_roues: '',
            genre: null,
            tonnage_carosserie: '',
            password1: null,
            optvehicule: '',
            nbredepiece: null,
            optionUtilitaire: '',
            carburant: null,
            marque: null,
            model: null,
            force: null,
            age: null,
            annee_mois: null,
            valeur: null,
            valeur_venale: null,
            valeurduvehicule: null,
            immatriculation: null,
            nbplace: null,
            garanties: [],
            assureurs: [],
            capital_AvRec: null,
            optionPersoTrans: null,
            option_Individuel_Accident: null,
            listeModeles : null,
            popupAutre: false,
            listeAssureurs: [],
            ListAuto: [],
            suivant: true,
            categorieFInale: null,
            nbAutoAdded: null,
            listMarque: [],
            listeMarque2roues: [],
            listeModeles2roues : null,
            listMarqueprime: [],
            selectedMarque: null,
            duree: null,
            dateEffet: null,
            dateEcheance: null,
            tonnage: null,
            carosserie: null,
            voidField: false,
            selectedcate: 'tourisme',
            options: [{
                    text: 'Tourisme',
                    value: 'tourisme'
                },
                {
                    text: '2 roues',
                    value: '2roues'
                },
                {
                    text: 'Utilitaires',
                    value: 'utilitaire'
                }
            ],
            Deb: null,
            selectedcarbu: '',
            options2: [{
                    text: 'Essence',
                    value: 'essence'
                },
                {
                    text: 'Diesel',
                    value: 'diesel'
                },
                {
                    text: 'Hybride',
                    value: 'hybride'
                },
                {
                    text: 'Electrique',
                    value: 'elecetrique'
                }

            ],
            types: [
                'text',
                'text',
                'number',
            ],
            stepper: 0,
            fields: [{
                    key: 'marque',
                    span: 'Marque'
                },
                {
                    key: 'model',
                    span: 'Modèle',
                    sortable: true,
                    sortDirection: 'desc'
                },
                {
                    key: 'immatriculation',
                    span: 'immatriculation'
                },
                {
                    key: 'categorie',
                    span: 'Catégorie'
                },
                {
                    key: 'dateCircule',
                    span: 'dateCircule'
                },
                {
                    key: 'force',
                    span: 'Puissance Fiscale'
                },
                {
                    key: 'volumetrie2_roues',
                    span: 'Volumetrie'
                },
                {
                    key: 'nbplace',
                    span: 'Nombre de Place'
                },
                {
                    key: 'valeur',
                    span: 'Valeur Neuf'
                },
                {
                    key: 'valeur_venale',
                    span: 'Valeur venale'
                },
                {
                    key: 'garanties',
                    span: 'garanties'
                }
            ],
            steps: [{
                    span: 'Etape 1',
                    completed: false,
                },
                {
                    span: 'Etape 2',
                    completed: false,
                },
                {
                    span: 'Etape 3',
                    completed: false,
                },
            ],

            show: true,
            //fin initialisation des models d'assuraf
            chart: null,
            pie: null,
            gridData: []
        }
    },
    watch: {
        marque (val) {
            this.onChangeSelectedMarque(val)
            this.onChangeSelectedMarque2roues(val)
        },
    },
    created() {
        this.init()
    },
    mounted() {
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
            this.getListePartenaire();
            this.getUserRoles()
            this.listMarque = _.uniq(MarqueVehicule, _.property('Marque'))
            this.listeModeles = _.uniq(MarqueVehicule, _.property('Type'))
            this.listeMarque2roues = _.uniq(Marque2roues, _.property(('Marque')))
            this.listeModeles2roues = _.uniq(Marque2roues, _.property('Type'))

            this.duree = 1
        } else {
            this.$router.go(this.$router.push('/login'))
        }
        window.addEventListener("resize", this.__resizeHanlder)
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }

        if (!this.pie) {
            return
        }

        window.removeEventListener("resize", this.__resizeHanlder)
        this.chart.dispose()
        this.pie.dispose()
        this.chart = null
        this.pie = null
    },
    methods: {
        validateImmatriculation() {
            const regex = /^[A-Za-z0-9]{2}-[A-Za-z0-9]{4}-[A-Za-z0-9]{2}$/;
            if (!regex.test(this.immatriculation)) {
            this.immatriculation = this.immatriculation.replace(/[^A-Za-z0-9-]/g, '');
            }
        },
        getUserRoles(){ 
            Api().get('/roles/all')
            .then(response => {
                this.listRoles = response.data.AllRoles
                for(let lr of response.data.AllRoles){
                    if(lr.name=="User"){
                        this.userRole = lr._id
                    }
                }
            }).catch(err => {console.log("failed getAllUserRoles");})
        },

        SaveUserFlette(){
        Api().post('/flette/addarray',{car: this.ListAuto, user: this.usersearch._id})
            .then((result) => { 
                if(result.data.msg){
                    this.$message.error(result.data.msg, this.AlertMessage)
                }else {
                    this.$message.success('Véhicule ajouté avec succés', this.AlertMessage)
                }
                })
            .catch((err) => {
                 console.log(err)
                 this.$message.error(result.data.msg, this.AlertMessage)
                });
        },

        getListVehicule(){
            if(this.myCars == 'mes_vehicule'){
                if(this.listCars.length == 0){
                    this.$message.error("Vous n'avez pas de véhicule !", this.AlertMessage)
                }else{
                this.ModalListeContrat = true
                this.userCars = this.listCars
                this.myCars = 'mes_vehicule'
                }
            }else if(this.myCars == 'nouveau') {
                this.e1 = 1
            }
            this.getUserCars()
        },

        getCarForDevis(item){
            this.carDetails = item

            const date1 = dayjs(this.carDetails.dateCircule);
            const date2 = dayjs(new Date().toISOString().substr(0, 10));
            let daydiff = date2.diff(date1, 'days');
            this.age = daydiff;

            if (this.age >= 360) {
                let year = Math.floor(this.age / 360);
                this.age = year;
                this.annee = year;
                this.year = this.age;
                this.an = this.annee_mois = 'an(s)'
            }

            if(this.carDetails.categorie == 'categorie 5'){
                this.categorie = '2_roues'
                this.offre_carte = true
                this.showOffre = false;
            } else if(this.carDetails.categorie != 'categorie 5') {
                this.categorie = null
                this.offre_carte = false
                this.showOffre = true;
                this.offre_pack = true
            }

                this.nbAutoAdded = this.nbAutoAdded + 1;
                this.flotte_mono = 'mono'
                this.e1 = 6
                this.ModalListeContrat = false
        },

        deleteUserCar (item){
        const UserCar = {
            id: item._id
        }

        Api().post("/flette/delete", UserCar)
            .then(response => {
                if (response.status == 200) {
                     Api().post("flette/listing/", {userid: this.usersearch._id})
                        .then(response => {
                            this.userCars = response.data.cars
                    })
                this.$message.success('Véhicule supprimé avec succès !', this.AlertMessage)
                }
            })
            .catch(err => {})

        },
        /*==========Fonction de nettoyage du formulaire apres recuperation des datas========*/
    ClearForm: function () {
      this.marque = '';
      this.model = '';
      this.categorie = '';
      this.force = '';
      this.age = '';
      this.nbplace = '';
      this.valeur = '';
      this.valeur_venale = '';
      this.immatriculation = '';
      this.carburant = '';
      this.option_Individuel_Accident = null;
      this.optionPersoTrans = null;
      this.capital_AvRec = null;
      this.garanties = ['RESPONSABILITE CIVILE']
    },
        /*=================Creation d'une liste de vehicules pour affichage Recap===============*/
    ListAutoCreateForCars: function () {
        this.Deduction_tonnage_carosserie();
        const Oneauto = {
        categorie: this.carDetails.categorie,
        tonnage: this.carDetails.tonnage,
        optionUtilitaire: this.carDetails.optionUtilitaire,
        volumetrie2_roues: this.carDetails.volumetrie2_roues,
        optionPersoTrans: this.optionPersoTrans,
        carburation: this.carDetails.carburation,
        type_vitre: this.carDetails.type_vitre,
        marque: this.carDetails.marque,
        carosserie: this.carDetails.carosserie,
        model: this.carDetails.model,
        force: this.carDetails.force,
        dateCircule: this.carDetails.dateCircule,
        age: this.carDetails.age,
        annee_mois: this.carDetails.annee_mois,
        nbplace: this.carDetails.nbplace,
        valeur: this.carDetails.valeur,
        valeur_venale: this.carDetails.valeur_venale,
        immatriculation: this.carDetails.immatriculation,
        flotte_mono: this.carDetails.flotte_mono,
        option_Individuel_Accident: this.carDetails.option_Individuel_Accident,
        garanties: this.garanties
      };
      if (this.capital_AvRec != null) {
        Oneauto.capitalAvRec = Number(this.capital_AvRec.replace(/[^0-9.-]+/g, ""))
      }
      if (this.categorie == '2_roues') {
        Oneauto.force = this.neant;
        Oneauto.dateCircule = this.neant;
        Oneauto.valeur = this.neant;
        Oneauto.valeur_venale = this.neant;
        Oneauto.nbplace = this.neant
        Oneauto.optionPersoTrans = this.optionPersoTrans
      }

      this.hideTablr = false;
      this.ListAuto.push(Oneauto);

      this.nbAutoAdded = this.nbAutoAdded + 1;

      this.ClearForm();
      if (this.nombreVehicules > 1) {
        if (this.nbAutoAdded < this.nombreVehicules) {
          this.e1 = 2
        }
        if (this.nbAutoAdded == this.nombreVehicules) {
          this.e1 = 7
        }
      } else {
        this.voidField = true
      }
    },
        /*=================Crée le tableau de la liste des véhicules==============*/
    createTable: function () {
        if(this.myCars != 'mes_vehicule')
        {
            if (this.garanties.length >= 1) 
            {
                this.ListAutoCreate()
            } else {
                this.$message.error('Veuillez cocher une garantie au minimum !', this.AlertMessage)
            }
            // if (this.nbAutoAdded == this.nombreVehicules) 
            // {
            //     if (this.garanties.length >= 1) {
            //         this.ListAutoCreate()
            //     } else {
            //         this.$message.error('Veuillez cocher une garantie au minimum !', this.AlertMessage)
            //     }

            // } else if (!this.nombreVehicules) 
            // {
            //     if (this.garanties.length >= 1) {
            //     if (this.ListAuto.length == 0) {
            //         this.ListAutoCreate()
            //     }
            //     } else {
            //         this.$message.error('Veuillez cocher une garantie au minimum !', this.AlertMessage)
            //     }
            // }
        }
        else if(this.myCars == 'mes_vehicule'){
            if(this.garanties.length >= 1){
                this.ListAutoCreateForCars()
                this.e1 = 7
            }else {
                this.$message.error('Veuillez cocher une garantie au minimum !', this.AlertMessage)
            }
        }

      if (store.state.isUserLoggedIn) {
        this.proprietaire = this.$store.state.user.prenom + ' ' + this.$store.state.user.nom
      }
    },
        init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },
    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },
    AvRecValidator(){
        if(this.capital_AvRec){
            this.AvRec = false
        }else {
            this.$message.error('Veuillez choisir un option !', this.AlertMessage)
        }
    },
    PersonnesTransValidator(){
        if(this.optionPersoTrans){
            this.PersoTransporteModal = false
        }else {
            this.$message.error('Veuillez choisir un option !', this.AlertMessage)
        }
    },
    individuelTransValidator(){
        if(this.option_Individuel_Accident){
            this.individiA = false
        }else {
            this.$message.error('Veuillez choisir un option !', this.AlertMessage)
        }
    },
    /*============= fonction pour controler les garanties tierce a ne cocher plus d'un ===========*/
    tierceControl() {
      let cpt = 0;
      for (let index = 0; index < this.garanties.length; index++) {
        if (this.garanties[index].trim() == 'TIERCE COLLISION' || this.garanties[index].trim() == 'TIERCE PLAFONNEE') {
          cpt = cpt + 1
        } else if (this.garanties[index].trim() == 'TIERCE COLLISION' || this.garanties[index].trim() == 'TIERCE COMPLETE') {
          cpt = cpt + 1
        } else if (this.garanties[index].trim() == 'TIERCE COMPLETE' || this.garanties[index].trim() == 'TIERCE PLAFONNEE') {
          cpt = cpt + 1
        } else if (this.garanties[index].trim() == 'TIERCE COLLISION' || this.garanties[index].trim() == 'TIERCE PLAFONNEE' || this.garanties[index].trim() == 'TIERCE COMPLETE') {
          cpt = cpt + 2
        }
      }
      if (cpt == 2) {
        this.$message.error('Impossible de prendre 2 garanties tierces en même temps !', this.AlertMessage)
        this.suivant = false
      } else if (cpt == 3) {
        this.$message.error('Impossible de prendre 3 garanties tierces en même temps !', this.AlertMessage)
        this.suivant = false
      } else {
        this.ValeurNeufModalFunction()
        this.suivant = true
      }
    },

    /*============= fonction pour controler les garanties tierce a ne cocher plus d'un ===========*/
    tierceControlAddGarantie() {
      let cpt = 0;
      for (let index = 0; index < this.CarToUpdate.garanties.length; index++) {
        if (this.CarToUpdate.garanties[index].trim() == 'TIERCE COLLISION' || this.CarToUpdate.garanties[index].trim() == 'TIERCE PLAFONNEE') {
          cpt = cpt + 1
        } else if (this.CarToUpdate.garanties[index].trim() == 'TIERCE COLLISION' || this.CarToUpdate.garanties[index].trim() == 'TIERCE COMPLETE') {
          cpt = cpt + 1
        } else if (this.CarToUpdate.garanties[index].trim() == 'TIERCE COMPLETE' || this.CarToUpdate.garanties[index].trim() == 'TIERCE PLAFONNEE') {
          cpt = cpt + 1
        } else if (this.CarToUpdate.garanties[index].trim() == 'TIERCE COLLISION' || this.CarToUpdate.garanties[index].trim() == 'TIERCE PLAFONNEE' || this.CarToUpdate.garanties[index].trim() == 'TIERCE COMPLETE') {
          cpt = cpt + 2
        }
      }
      if (cpt == 2) {
        this.$message.error('Impossible de prendre 2 garanties tierces en même temps !', this.AlertMessage)
        this.suivant = false
      } else if (cpt == 3) {
        this.$message.error('Impossible de prendre 3 garanties tierces en même temps !', this.AlertMessage)
        this.suivant = false
      } else {
        this.ValeurNeufModalAddGarantiesFunction()
        this.suivant = true
      }
    },

    optionPersonneTransControl() {
      if (this.optionPersoTrans) {
        this.PersoTransporteModal = false
      } else {
        this.$message.error('Veuillez choisir un option !', this.AlertMessage)
      }
    },
    
    PersoTransporteModalFunction() {
      let trouver = 0;
      for (let index = 0; index < this.garanties.length; index++) {
        if (this.garanties[index] == 'PERSONNES TRANSPORTEES') {
          trouver = 1
        }
      }
      if (trouver == 1) {
        this.PersoTransporteModal = true
      } else {
        this.optionPersoTrans = null
      }
    },

    PersoTransporteModalFunctionAddGaranties() {
      let trouver = 0;
      for (let index = 0; index < this.CarToUpdate.garanties.length; index++) {
        if (this.CarToUpdate.garanties[index] == 'PERSONNES TRANSPORTEES') {
          trouver = 1
        }
      }
      if (trouver == 1) {
        this.PersoTransporteModal = true
      } else {
        this.optionPersoTrans = null
      }
    },
    
    AvRecModalFunction() {
        if (!this.CarToUpdate) {
            let trouver = 0
            for (let index = 0; index < this.garanties.length; index++) {
            if (this.garanties[index] == 'AVANCE SUR RECOURS') {
                trouver = 1
            }
            }
            if (trouver == 1) {
            this.AvRec = true
            } else {
            this.capital_AvRec = null
            }
        } else {
            let trouver = 0;
            for (let index = 0; index < this.CarToUpdate.garanties.length; index++) {
            if (this.CarToUpdate.garanties[index] == 'AVANCE SUR RECOURS') {
                trouver = 1
            }
            }
            if (trouver == 1) {
            this.AvRecAddNew = true
            } else {
            this.CarToUpdate.capital_AvRec = null
            }
        }

    },
    
    disabledBeforeToday(date) {
        const today = new Date();
        return date > today;
    },
    
    zeroControl(){
            if(this.duree<=0){
                this.duree = null
            }
    },
        numberMonth() {
            this.zeroControl()
            if (this.duree <= 12) {
                return true
            }else {

                return false
            }
        },
        /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
        onlyNumber($event) {
            let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
            if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
                $event.preventDefault();
            }
        },
        AlertMessage() {
        this.$message({
          showClose: false,
          duration : 5000
        });
      },
        errorNotif(){
			this.$notify({
				title: 'Error',
				message: 'Numéro obligatoire !',
				position: 'top-right',
				type: 'error'
			});
		},
        errorNotifCreateUser(){
			this.$notify({
				title: 'Error',
				message: 'Tout les Champs sont obligatoires !',
				position: 'top-right',
				type: 'error'
			});
		},
        successNotifCreateUser(){
			this.$notify({
				title: 'Success',
				message: 'Utilisateur crée avec succés !',
				position: 'top-right',
				type: 'succes'
			});
		},

        check_client_search(){
            if(this.clientsearch == 'téléphone'){
                this.NumberInput = true 
                this.EmailInput = false
                this.emailclient = null
                this.clientOk = false
                this.clientnonOk = false
            } else if(this.clientsearch == 'email'){
                this.NumberInput = false 
                this.EmailInput = true
                this.telephoneclient = null
                this.clientOk = false
                this.clientnonOk = false
            }
        },
        showGaranties(){
            if(this.garanties == this.packStandart.garanties){
              this.optionPersoTrans = 'option 1'
              this.text_packStandart = true
              this.text_packInter = false
              this.text_packAvance = false
              this.offre_pack_full = false
              this.text_packFull_1 = false
              this.text_packFull_2 = false
              this.text_packFull_3 = false
              this.offre_full = false
            }else if(this.garanties == this.packIntermediaire.garanties) {
              this.optionPersoTrans = 'option 1'
              this.text_packStandart = false
              this.text_packInter = true
              this.text_packAvance = false
              this.offre_pack_full = false
              this.text_packFull_1 = false
              this.text_packFull_2 = false
              this.text_packFull_3 = false
              this.offre_full = false
            }else if(this.garanties == this.packAvance.garanties){
              this.optionPersoTrans = 'option 1'
              this.capital_AvRec = '500000'
              this.text_packStandart = false
              this.text_packInter = false
              this.text_packAvance = true
              this.offre_pack_full = false
              this.text_packFull_1 = false
              this.text_packFull_2 = false
              this.text_packFull_3 = false
              this.offre_full = false
            }else if(this.garanties == this.packFull){
              this.text_packStandart = false
              this.text_packInter = false
              this.text_packAvance = false
              this.offre_full = true
            }
          },
          showGarantiesPackFull(){
        if(this.garanties == this.packFull.packToutRisques.garanties){
          this.optionPersoTrans = 'option 1'
          this.capital_AvRec = '500000'
          this.ValeurNeufModalFunction()

          this.text_packFull_1 = true
          this.text_packFull_2 = false
          this.text_packFull_3 = false
        }else if(this.garanties == this.packFull.packFullCollision.garanties) {
          this.optionPersoTrans = 'option 1'
          this.capital_AvRec = '500000'
          this.ValeurNeufModalFunction()
          this.text_packFull_1 = false
          this.text_packFull_2 = true
          this.text_packFull_3 = false
        }else if(this.garanties == this.packFull.packFull_3.garanties){
          this.text_packFull_1 = false
          this.text_packFull_2 = false
          this.text_packFull_3 = true
        }

      },
          show_offre() {
            if(this.offre == 'offre_pack'){
              this.offre_pack = true
              this.offre_carte = false
              this.garanties = []
            }else if(this.offre == 'offre_carte'){
              this.offre_pack = false
              this.offre_carte = true
              this.text_packStandart = false
              this.text_packInter = false
              this.text_packAvance = false
              this.text_packFull_1 = false
              this.text_packFull_2 = false
              this.text_packFull_3 = false
              this.offre_full = false
              this.garanties = []
            }
          },
        ValeurNeufModalFunction() {
            let trouver = 0;
            for (let index = 0; index < this.garanties.length; index++) {
                if (this.garanties[index] == 'TIERCE COMPLETE') {
                    trouver = 1
                } else if (this.garanties[index] == 'TIERCE COLLISION') {
                    trouver = 2
                }
            }
            if (trouver == 1 || trouver == 2) {
                this.dateMiseEnCirculeModal = true
                //this.VNeuf = true
            } else {
                this.valeur = this.neant
            }
        },

        CheckDateMiseEnCirculation: function () {
            let trouver = 0;
            for (let index = 0; index < this.garanties.length; index++) {
                if (this.garanties[index] == 'TIERCE COMPLETE' || this.garanties[index] == 'TIERCE COLLISION') {
                    trouver = 1
                }
            }

            //this.valeur_venale

            if (trouver == 1 && this.dateCircule) {
                this.dateMiseEnCirculeModal = false
                this.VNeuf = true
            } else {
                this.$message.error('Veuillez saisir la date 1ére mise en circulation!', this.AlertMessage)
            }
        },

        CheckValeurNeuf: function () {
            let trouver = 0;
            for (let index = 0; index < this.garanties.length; index++) {
                if (this.garanties[index] == 'TIERCE COMPLETE' || this.garanties[index] == 'TIERCE COLLISION') {
                    trouver = 1
                }
            }

            //this.valeur_venale

            if (trouver == 1 && !this.valeur || this.valeur == 0 && !this.valeur_venale || this.valeur_venale == 0) {
                this.$message.error('Veuillez saisir la valeur actuelle et la veleur à neuf du véhicule !', this.AlertMessage)
            } else if (trouver == 1 && this.valeur && this.valeur != 0) {
                if (Number(this.valeur_venale.replace(/[^0-9.-]+/g, "")) <= Number(this.valeur.replace(/[^0-9.-]+/g, ""))) {
                    this.VNeuf = false
                } else {
                    this.$message.error('La valeur à neuf et la valeur actuelle du véhicule ne sont pas cohérentes !', this.AlertMessage)
                }
            }
        },

        getUserCars(){
            Api().post("flette/listing/", {userid: this.userCarId})
                .then(response => {
                this.listCars = response.data.cars
                });
        },

        rechercherClient() {
        if(this.telephoneclient){
          const validPhone = validatePhone(this.telephoneclient);
          this.errors.phone = validPhone.error;

          if(validPhone.valid == true){
            Api().get('/user/phone/' + this.telephoneclient)
                .then(resultat => {
                  if (resultat.data.telephone_port === this.telephoneclient) {
                    this.clientOk = true
                    this.clientnonOk = false
                    this.usersearch = resultat.data;

                    this.userCarId = this.usersearch._id

                    let useridentity = this.usersearch._id
                    this.$store.dispatch('setClient', resultat.data)

                     Api().post("flette/listing/", {userid: useridentity})
                        .then(response => {
                            this.listCars = response.data.cars
                    });
                  }

                })
                .catch(e => {
                  this.clientnonOk = true
                  this.clientOk = false
                  this.telephoneC = this.telephoneclient;
                })
          } else {
            this.$message.error('Le numéro de téléphone doit contenir 9 chiffres au minimum !', this.AlertMessage)
          }
        }else {
          this.$message.error('Numéro de téléphone obligatoire !', this.AlertMessage)
        }


      },

        rechercheClientByMail(){
            if(this.emailclient){

              const validEmail = validateEmail(this.emailclient);
              this.errors.email = validEmail.error;

              if(validEmail.valid == true){
                Api().get('/user/email/' +this.emailclient)
                    .then(resultat =>{
                      if (resultat.data.email === this.emailclient) {
                        this.clientOk = true
                        this.clientnonOk = false
                        this.usersearch = resultat.data;
                        this.userCarId = this.usersearch._id

                        let useridentity = this.usersearch._id
                        this.$store.dispatch('setClient', resultat.data)
                         Api().post("flette/listing/", {userid: useridentity})
                        .then(response => {
                            this.listCars = response.data.cars
                    });
                      }
                    }).catch(e => {
                  this.clientnonOk = true
                  this.clientOk = false
                  this.emailC = this.emailclient;
                })
              } else {
                this.$message.error('Veuillez entrer un email valide !', this.AlertMessage)
              }
            }else {
            this.$message.error('Adresse e-mail obligatoire !', this.AlertMessage)

            }
        },

        yearsDiff() {
            const date1 = dayjs(this.dateCircule);
            const date2 = dayjs(new Date().toISOString().substr(0, 10));
            let daydiff = date2.diff(date1, 'days');
            this.age = daydiff

           if (this.age <= 29) {
                this.an = this.annee_mois = 'jour(s)'
            } else if (this.age >= 30, this.age <= 360) {
                let year = Math.floor(this.age / 30)
                this.age = year;
                this.an = this.annee_mois = 'mois'
            } else if (this.age >= 360) {
                let year = Math.floor(this.age / 360)
                this.age = year;
                this.an = this.annee_mois = 'an(s)'
            }

        },

        showidclient() {
            this.showId = false
            this.showconfirmclient = true
            this.shownameclient = false

        },

        hidesearch() {
            this.hidesearchClient = false
            this.e1 = 0
        },

        rechercherbyphone() {
            if (this.usersearch.telephone_port === this.telephoneclient) {
                this.clientOk = true
                this.clientnonOk = false

            }
            if (this.usersearch.telephone_port !== this.telephoneclient) {
                this.clientnonOk = true
                this.clientOk = false
                this.telephoneC = this.telephoneclient;

            }
        },

        validMarqueModel() {
            if (this.categorie == '2_roues') {
                if (this.marque && this.model) {
                    this.popupAutre = false
                    this.e1 = 5
                } else {
                    this.$message.error('Champs obligatoires !', this.AlertMessage)
                }
            } else {
                if (this.marque && this.model) {
                    this.popupAutre = false
                    this.e1 = 4
                } else {
                    this.$message.error('Champs obligatoires !', this.AlertMessage)
                }
            }
        },

        flotteselect: function () {
            this.hideDive_vehicule = false
        },

        onReset(evt) {
            evt.preventDefault();
            this.user.email = '';
            this.user.name = '';
            this.user.food = null;
            this.user.checked = [];
            this.show = false;
            this.$nextTick(() => {
                this.show = true
            });
        },

        onChangeSelectedMarque: function (mark) {
            if (typeof mark == 'string') {
                if (mark == 'AUTRE') {
                    this.popupAutre = true;
                    this.marque = ''
                } else {
                    let selMarque = _.where(MarqueVehicule, {
                        'Marque': mark
                    })
                    this.listeModeles = _.uniq(selMarque, _.property('Type'))
                    this.selectedMarque = mark
                }
            }

        },

         onChangeSelectedMarque2roues: function (mark) {
            if (typeof mark == 'string') {
                if (mark == "AUTRE") {
                    this.popupAutre = true;
                    this.marque = ''
                } else {
                    let selMarque = _.where(Marque2roues, {
                        'Marque': mark
                    })
                    this.listeModeles2roues = _.uniq(selMarque, _.property('Type'))
                    this.listeModeles2roues [0] = "AUTRE"
                    this.selectedMarque = mark

                }
            } else if (typeof mark == 'object') {
                if (mark.Marque == 'AUTRE') {
                    this.popupAutre = true;
                    this.marque = ''
                } else {
                    let selMarque = _.where(Marque2roues, {
                        'Marque': mark
                    })
                    this.listeModeles2roues = _.uniq(selMarque, _.property('Type'))
                    this.listeModeles2roues[0] = "AUTRE";
                    this.selectedMarque = mark.Marque
                    this.marque = this.selectedMarque
                    this.selectedMarque = mark
                }
            }

        },

        //liste de touts les partenaires
        getListePartenaire: function () 
        {
        switch (this.$store.state.user.role) {
            case 'Agent':
            Api().post("/branchecourtier/get/partenaire", {userid:this.$store.state.user._id, branche:"Automobile"})
            .then((response) => {
                this.listeAssureurs = response.data.partenaires[0].assureurs
            })
            .catch((error) => {
                console.log(error);
            });
            break;
        
            default:
            Api().get("/partenaire/partners/auto")
            .then((response) => {
                this.listeAssureurs = response.data.partenaires;
            })
            .catch((error) => {
                console.log(error);
            });
            break;
        }
            
        
        },

        HubspotSend(param)
        {
            let data = {
                "email": param.email,
                "firstname": param.prenom,
                "lastname": param.nom,
                "phone": param.telephone_port,
                "inscrit": "Dash"
            };
            Api().post('/testimonial/hubspot', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

        MailChimpInscriptionCompte(param)
        {
            let data = {
                "email": param.email,
                "FNAME": param.prenom,
                "LNAME": param.nom,
                "PHONE": param.telephone_port,
            };
            Api().post('/testimonial/mailchimp', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },
        //Creation d'un user avant de faire son devis
        createuser: function () {

            this.generatePassword()
            if (this.nom && this.prenom && this.emailclient && this.genre && this.telephoneclient) 
            {
                const user = {
                        nom: this.nom,
                        prenom: this.prenom,
                        datenaissance: this.datenaissance,
                        profession: this.profession,
                        adresse: this.adresse,
                        sexe: this.genre,
                        telephone_port: this.telephoneclient,
                        email: this.emailclient,
                        password: this.password1,
                        confirmPass: this.password1,
                        role:  this.userRole,
                        createdby:store.state.user._id,
                        photo: this.file,
                        piece_identite: this.piece_identite,
                        numero_piece: this.numero_piece,
                         plateforme : 'Dash',
                        created: dayjs(new Date()).format('YYYY-MM-DD')
                }
                Api().post('user/register', user)
                        .then(resultat => {
                            this.HubspotSend(user)
                            this.MailChimpInscriptionCompte(user)
                            if (resultat.status == 200) {
                                if(resultat.data.numero){
                                    this.$message.error(resultat.data.msg, this.AlertMessage)
                                }else{
                                    this.$store.dispatch('setClient', resultat.data.data)
                                    this.usersearch = resultat.data.data
                                    this.AddUser = false
                                    this.clientnonOk = false
                                    this.hidesearchClient = false
                                    this.e1 = 0
                                }
                            } else {
                                this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                            }

                })
                .catch(err => {
                    this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                })
               
            } else {
                this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
            }

        },

        generatePassword: function () {
            this.password1 = customPassword.customPassword(this.nom, this.prenom)
        },

        //calclu date echéance
        dateDiff: function () {
            if (this.dateEffet && this.duree) {
                let inter = dayjs(this.dateEffet).add(this.duree, 'month').format('YYYY-MM-DD');
                this.dateEcheance = dayjs(inter).add(-1, 'day').format('YYYY-MM-DD')
            } else {
                //this.duree = 1
                this.dateEffet = dayjs(new Date()).format('YYYY-MM-DD');
                let inter = dayjs(this.dateEffet).add(this.duree, 'month').format('YYYY-MM-DD');
                this.dateEcheance = dayjs(inter).add(-1, 'day').format('YYYY-MM-DD')
            }
        },

        Deduction_tonnage_carosserie() 
        {
            if (this.categorie == 'tourisme') {
                this.categorieFInale = 'categorie 1'
            } else if (this.categorie == 'utilitaire' && this.optionUtilitaire == 'materiel_perso') {
                this.categorieFInale = 'categorie 2'
                if (this.tonnage_carosserie == 'Autre carosserie jusqu\'à 3,5 tonnes') {
                    this.tonnage = '3,5 Tonnes'
                    this.carosserie = 'autres carosseries'
                } else if (this.tonnage_carosserie == 'Autre carosserie plus de 3,5 tonnes') {
                    this.tonnage = 'Plus de 3,5 Tonnes'
                    this.carosserie = 'autres carosseries'
                } else if (this.tonnage_carosserie == 'carosserie tourisme') {
                    this.tonnage = '3,5 Tonnes'
                    this.carosserie = 'carosserie tourisme'
                }
            } else if (this.categorie == 'utilitaire' && this.optionUtilitaire == 'materiel_tiers') {
                this.categorieFInale = 'categorie 3'
                if (this.tonnage_carosserie == 'Autre carosserie jusqu\'à 3,5 tonnes') {
                    this.tonnage = '3,5 Tonnes'
                    this.carosserie = 'autres carosseries'
                } else if (this.tonnage_carosserie == 'Autre carosserie plus de 3,5 tonnes') {
                    this.tonnage = 'Plus de 3,5 Tonnes'
                    this.carosserie = 'autres carosseries'
                }
            } else if (this.categorie == '2_roues') {
                this.categorieFInale = 'categorie 5'
            }
        },

        ClearForm: function () {
            this.marque = ''
            this.model = ''
            this.marque = ''
            //this.categorie = ''
            this.force = ''
            this.age = ''
            this.nbplace = ''
            this.valeur = ''
            this.valeur_venale = null
            this.immatriculation = ''
            this.proprietaire = ''
            this.carburant = ''
            this.option_Individuel_Accident = null
            this.optionPersoTrans = null
            this.capital_AvRec = null
            this.garanties = ['RESPONSABILITE CIVILE']
        },

        AutreCarburation(carburantChecked) {
            if (carburantChecked == 'Hybride' || carburantChecked == 'Electrique') {
                return 'Essence'
            } else {
                return carburantChecked
            }

        },
        /*================================================================================================================================*/
        /*================================================================================================================================*/
        /*==========CETTE PARTIE EST SPECIALEMENT POUR UNE SERIE DE FONCTION PERMETTANT DE VALIDER LES MODELS POUR CHAQUE STEPPER=========*/
        //Validation des elements du Stepper 1
        Stepper1Validator() {
            if (this.categorie) {
                if(this.categorie === 'tourisme'){
                    //=============== partie VP ==============
                    if(this.carburant){
                        if(this.force) {
                            if(this.nbplace){
                                //if(Number(this.valeur_venale.replace(/[^0-9.-]+/g, "")) >= 50000){
                                    //if(this.dateCircule){
                                        this.e1 = 3
                                    // }else {
                                    //     this.$message.error('La date 1ère mise en circulation du véhicule est obligatoire !', this.AlertMessage)
                                    // }
                                // }else {
                                //     this.$message.error('La valeur actuelle du véhicule est obligatoire !', this.AlertMessage)
                                // }
                            }else {
                                this.$message.error('Le nombre de place du véhicule est obligatoire !', this.AlertMessage)
                            }
                        } else {
                            this.$message.error('Le nombre de chevaux du véhicule est obligatoire !', this.AlertMessage)
                        }
                    }else {
                        this.$message.error('La carburation du véhicule est obligatoire !', this.AlertMessage)
                    }
                } else if (this.categorie === 'utilitaire') {
                    //=============== partie utilitaire ==========
                    if(this.optionUtilitaire){
                        if(this.tonnage_carosserie){
                            if(this.carburant){
                                if(this.force) {
                                    if(this.nbplace){
                                        //if(Number(this.valeur_venale.replace(/[^0-9.-]+/g, "")) >= 50000){
                                           // if(this.dateCircule){
                                                this.e1 = 3
                                            // }else {
                                            //     this.$message.error('La date 1ère mise en circulation du véhicule est obligatoire !', this.AlertMessage)
                                            // }
                                        // }else {
                                        //     this.$message.error('La valeur actuelle du véhicule est obligatoire !', this.AlertMessage)
                                        // }
                                    }else {
                                        this.$message.error('Le nombre de place du véhicule est obligatoire !', this.AlertMessage)
                                    }
                                } else {
                                    this.$message.error('Le nombre de chevaux du véhicule est obligatoire !', this.AlertMessage)
                                }
                            }else {
                                this.$message.error('La carburation du véhicule est obligatoire !', this.AlertMessage)
                            }
                        }else {
                            this.$message.error('Le tonnage du véhicule est obligatoire !', this.AlertMessage)
                        }
                        }else {
                            this.$message.error('L\'option utilitaire du véhicule est obligatoire !', this.AlertMessage)
                        }
                    }
                
                else {
                    //=============== partie 2 roues ==========
                    if(this.volumetrie2_roues){
                        this.e1 = 3
                    }else {
                        this.$message.error('La volumétrie du véhicule est obligatoire !', this.AlertMessage)
                    }
                }
            } else {
                this.$message.error('La catégorie du véhicule est obligatoire !', this.AlertMessage)
            }
        },

        /*================================================================================================================================*/
        /*================================================================================================================================*/
        /*================================================================================================================================*/
        /*=================Creation d'une liste de vehicules pour affichage Recap===============*/
        ListAutoCreate: function ()
        {
            this.Deduction_tonnage_carosserie()

            const Oneauto = {
                tonnage: this.tonnage,
                categorie: this.categorieFInale,
                volumetrie2_roues: this.volumetrie2_roues,
                marque: this.marque,
                carosserie: this.carosserie,
                model: this.model,
                force: this.force,
                dateCircule: this.dateCircule,
                nbplace: this.nbplace,
                garanties: this.garanties,
                flotte_mono: 'mono',
                immatriculation: this.immatriculation,
                optionPersoTrans : this.optionPersoTrans,
            }


            if (this.capital_AvRec != null) {
                Oneauto.capitalAvRec = Number(this.capital_AvRec.replace(/[^0-9.-]+/g, ""))
            }

            if (this.categorie !== '2_roues')
            {
                try {

                    Oneauto.categorie   = this.categorieFInale,
                    Oneauto.tonnage     = this.tonnage,
                    Oneauto.optionUtilitaire  = this.optionUtilitaire,
                    Oneauto.volumetrie2_roues = this.volumetrie2_roues,
                    Oneauto.optionPersoTrans  = this.optionPersoTrans,
                    Oneauto.carburation     = this.AutreCarburation(this.carburant),
                    Oneauto.marque          = this.marque,
                    Oneauto.carosserie      = this.carosserie,
                    Oneauto.model           = this.model,
                    Oneauto.force           = this.force,
                    Oneauto.dateCircule     = this.dateCircule,
                    Oneauto.age             = this.age,
                    Oneauto.annee_mois      = this.annee_mois,
                    Oneauto.nbplace         = Number(this.nbplace),
                    Oneauto.valeur          = this.testVar(this.valeur),
                    Oneauto.valeur_venale   = Number(this.valeur_venale.replace(/[^0-9.-]+/g, "")),
                    Oneauto.immatriculation = this.immatriculation,
                    Oneauto.flotte_mono     = 'mono',
                    Oneauto.garanties = this.garanties
                } catch (error) {
                    console.log(error)
                }
            }else{
                Oneauto.nbplace = 2
                Oneauto.valeur = 0
                Oneauto.valeur_venale = 0
            }

            this.ListAuto.push(Oneauto)

            this.nbAutoAdded = this.nbAutoAdded + 1

            //this.ClearForm()
           
        },

        testVar:function(val){
             if (val!=null)
            {
                return Number(val.replace(/[^0-9.-]+/g, ""));
            } else {
                return "0";
            }
        },

        SendData() {
            if (this.dateEffet) {
                if (this.assureurs.length >= 1) {

                    if (this.garanties.length >= 1) {
                        let trouver = 0;
                    for (let index = 0; index < this.garanties.length; index++) {
                        if (this.garanties[index] == 'TIERCE COMPLETE' || this.garanties[index] == 'TIERCE COLLISION') {
                            trouver = 1
                        }else {
                            trouver = 0
                        }
                    }
                    this.modalgenerationloading = true

                    if (trouver == 1) {
                        if(this.age <= 5) {
                            this.ListAutoCreate()

                            let OneautoToSave = {
                                dateEffet: dayjs(this.dateEffet).format('YYYY-MM-DD'),
                                dateEcheance: this.dateEcheance,
                                createur: this.$store.state.user._id,
                                branche: "Automobile",
                                duree: this.duree,
                                dateSouscription: dayjs(new Date()).format('YYYY-MM-DD'),
                                vehicule: this.ListAuto,
                                assureurs: this.assureurs,
                                plateforme : 'Dash',
                            }  

                            //console.log(':::::::: data devis for simulate :::::::::::::::', OneautoToSave)

                            if (this.$store.state.user.role == 'Agent') {
                                OneautoToSave.courtierid = this.$store.state.user.societe
                            }

                            this.modalgenerationloading = true

                            Api().post('simulator/add', OneautoToSave)
                            .then(result => {
                                if (result.data.message) {
                                    let data = {
                                        ...result.data,
                                        duree: this.duree,
                                    }
                                    this.$store.dispatch('setDevis', data)
                                    setTimeout(() => (
                                            this.modalgenerationloading = false,
                                            this.$router.go(this.$router.push({name: 'liste_devis_simulation'}))
                                        ), 4000);
                                }
                            })
                        }else {
                            this.dateMiseEnCirculeModal = false
                            this.garanties = []
                            this.$message.error("L'âge du véhicule ne permet pas de choisir les packs tierces!", this.AlertMessage)
                        }
                    } else {
                        this.ListAutoCreate()

                        let OneautoToSave = {
                            dateEffet: dayjs(this.dateEffet).format('YYYY-MM-DD'),
                            dateEcheance: this.dateEcheance,
                            createur: this.$store.state.user._id,
                            branche: "Automobile",
                            duree: this.duree,
                            dateSouscription: dayjs(new Date()).format('YYYY-MM-DD'),
                            vehicule: this.ListAuto,
                            assureurs: this.assureurs,
                            plateforme : 'Dash',
                        }  

                        console.log(':::::::: data devis for simulate 1 :::::::::::::::', OneautoToSave)


                        if (this.$store.state.user.role == 'Agent') {
                            OneautoToSave.courtierid = this.$store.state.user.societe
                        }

                        this.modalgenerationloading = true

                        Api().post('simulator/add', OneautoToSave)
                        .then(result => {
                            if (result.data.message) {
                                let data = {
                                    ...result.data,
                                    duree: this.duree,
                                }
                                this.$store.dispatch('setDevis', data)
                                setTimeout(() => (
                                        this.modalgenerationloading = false,
                                        this.$router.go(this.$router.push({name: 'liste_devis_simulation'}))
                                    ), 4000);
                            }
                        })
                    }
                    } else {
                        this.$message.error("Choisissez au minimum une garantie !", this.AlertMessage)
                    }
                } else {
                    this.$message.error("Choisissez au minimum un assureur !", this.AlertMessage)
                }
            } else {
                this.$message.error("La date d'effet est obligatoire !", this.AlertMessage)
            }
        },




        __resizeHanlder: _.throttle(function(e) {
            if (this.chart) {
                this.chart.resize()
            }
            if (this.pie) {
                this.pie.resize()
            }
        }, 700),
    }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables.scss";
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

.page-ecommerce-dashboard {
    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .table-box {
        .item-box {
            &.item-product {
                .product-image {
                    width: 50px;
                    margin-right: 15px;
                    float: left;

                    img {
                        width: 100%;
                    }
                }
            }

            &.item-status {
                padding: 5px 10px;

                &.status- {
                    &Complete {
                        background: rgba(44, 196, 120, 0.25);
                    }
                    &Pending {
                        background: rgba(247, 186, 42, 0.25);
                    }
                    &Returned {
                        background: rgba(243, 24, 71, 0.25);
                    }
                    &Paid {
                        background: rgba(45, 109, 211, 0.25);
                    }
                }
            }
        }
    }
}
</style>
